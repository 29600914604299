import { useContext } from "react";
import { Row, Col,Container } from "react-bootstrap";
import Hostnetlogo from "../../assets/Hostnetlogo";
import UserContext from "../../context";
import { FaQuestionCircle, Header, HeaderRow, MainContainer, Passo, PassoBox } from "../Home/styles";
import { FaEnvelope } from "./styles";

const Confirmacao =()=>{
    const context=useContext(UserContext);

    return(
        <>
            <Header>
                <Container className="ps-4 pe-4">
                        <HeaderRow>
                            <Col md={3}className="d-flex align-items-center justify-content-center justify-content-md-start"><a href="https://www.hostnet.com.br/" target="_blank"><Hostnetlogo/></a></Col>
                            <Col md={6}className="d-none d-md-flex align-items-center justify-content-center"><h1 className="d-none d-lg-flex">Hospede seu site ou registre seu domínio</h1></Col>
                            <Col md={3}className="d-none d-md-flex flex-column  align-items-end justify-content-center"><a href="https://www.hostnet.com.br/atendimento-ao-cliente/" target='_blank'><FaQuestionCircle/></a></Col>
                        </HeaderRow>
                </Container>
            </Header>
            <main>
            {context.confirmation?<MainContainer className="mt-5 mb-5 ps-md-5 pe-md-5 ps-4 pe-4">
                    <Row>
                        <PassoBox className="mb-2 ps-md-4">
                            
                            <h4>Mensagem enviada com sucesso!</h4>
                        </PassoBox>
                    </Row>
                    <Row>
                        <p className="p-0 ps-md-4 pt-1">Sua mensagem foi recebida por nossa equipe de atendimento e será respondida em até 24h.</p>
                        <p className="p-0 ps-md-4 pt-1">Em breve você receberá um e-mail contendo informações sobre o seu contato.</p>
                        <p className="p-0 ps-md-4 pt-1">Nosso serviço de atendimento ao cliente funciona 24h de segunda à sexta-feira. Sábados, domingos e feriados das 8h às 18h.</p>
                        <p className="p-0 ps-md-4 pt-1">Conheça outros canais de atendimento: <a href="https://www.hostnet.com.br/atendimento-ao-cliente/" target='_blank'>https://www.hostnet.com.br/atendimento-ao-cliente/</a></p>
                        <p className="p-0 ps-md-4 pt-1">Conheça nossa área de ajuda: <a href="https://ajuda.hostnet.com.br/" target='_blank'>https://ajuda.hostnet.com.br/</a></p>
                        <p className="p-0 ps-md-4 pt-1">Veja nossas últimas novidades: <a href="https://www.hostnet.com.br/blog/" target='_blank'>https://www.hostnet.com.br/blog/</a></p>
                    </Row>
                </MainContainer>:<MainContainer className="mt-5 mb-5 ps-md-5 pe-md-5 ps-4 pe-4">
                    <Row>
                        <PassoBox className="mb-2 ps-md-4">
                            
                            <h4>Tire suas dúvidas em nossa central de ajuda</h4>
                        </PassoBox>
                    </Row>
                    <Row >
                        <p className="p-0 ps-md-4 pt-1">Nosso serviço de atendimento ao cliente funciona 24h de segunda à sexta-feira. Sábados, domingos e feriados das 8h às 18h.</p>
                        <p className="p-0 ps-md-4 pt-1">Conheça outros canais de atendimento: <a href="https://www.hostnet.com.br/atendimento-ao-cliente/" target='_blank'>https://www.hostnet.com.br/atendimento-ao-cliente/</a></p>
                        <p className="p-0 ps-md-4 pt-1">Conheça nossa área de ajuda: <a href="https://ajuda.hostnet.com.br/" target='_blank'>https://ajuda.hostnet.com.br/</a></p>
                        <p className="p-0 ps-md-4 pt-1">Veja nossas últimas novidades: <a href="https://www.hostnet.com.br/blog/" target='_blank'>https://www.hostnet.com.br/blog/</a></p>
                    </Row>
                </MainContainer>}
            </main>
        </>
    );
};

export default Confirmacao;