import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from "react-query";
import App from './App';
import { queryClient } from './pages/Services/queryClient';
import GlobalStyle from './styles/globalStyles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <GlobalStyle/>
  </QueryClientProvider>
);
