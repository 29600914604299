import { forwardRef,useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Asteristico, MandatoryText, TextArea, TextInputStyled } from "./styles";

interface IProps{
    label:string;
    type:string;
    mandatory:boolean;
    value:string;
    onChange?:React.ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement>|undefined;
    className?:string ;
    externalValidationText?:string;
    refresh?:any;
    maxLength:any;
    rows?:any;
    disabled?:any;
    style?:React.CSSProperties | undefined;
}

const TextInputComponent =({style,label,type,mandatory,value='',onChange,className,externalValidationText,refresh,maxLength,rows,disabled}:IProps)=>{
    const[isEmpty,setIsEmpty]=useState<boolean>(false);

    const isEmptyHandle=()=>{
        if(!value&&mandatory){
            setIsEmpty(true);
        }else{
            setIsEmpty(false);
        }
    }
    const typeRender=()=>{
        if(type==="textarea"){
            return <TextArea style={style} maxLength={maxLength} rows={rows|5} value={value} onChange={onChange} onBlur={isEmptyHandle} className={mandatory?isEmpty||externalValidationText?'red':'':''}/>
        }else{
            return <TextInputStyled disabled={disabled} maxLength={maxLength} id="text" value={value} onChange={onChange} onBlur={isEmptyHandle} className={mandatory?isEmpty||externalValidationText?'red':'':''}/>
        }
    }
    useEffect(()=>{
        if(value.length>0){
            isEmptyHandle()
        }
        
    },[value]);

    useMemo(()=>{
        if(refresh>0){
            isEmptyHandle();
        }
        
    },[refresh]);

    return(
        <>
            <label htmlFor="text" className={className||''}>{label}{mandatory&&<Asteristico> *</Asteristico>}</label>
            {typeRender()}
            {isEmpty&&<MandatoryText>O campo {label} deve ser preenchido.</MandatoryText>}
            {!isEmpty&&externalValidationText&&<MandatoryText>{externalValidationText}</MandatoryText>}
        </>
    );
};

export default TextInputComponent;