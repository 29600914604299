import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserContext from "./context";

import Confirmacao from "./pages/Confirmacao";
import Home from "./pages/Home";
import Ticket from "./pages/Ticket";

function App() {
  const [confirmation,setConfirmation]=useState<boolean | null>(null);

  return (
    <UserContext.Provider value={{confirmation,setConfirmation}}>
      <BrowserRouter>
        <Routes>
          
            <Route element={ <Home/>} path='/'/>
            <Route element={ <Confirmacao/>} path='/confirmacao'/>
            <Route element={ <Ticket/>} path='/ticket/:hash'/>
            <Route element={ <Home/>} path='*'/>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
