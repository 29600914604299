import { useContext, useEffect, useState } from "react";
import { Row, Col,Container } from "react-bootstrap";
import Hostnetlogo from "../../assets/Hostnetlogo";
import UserContext from "../../context";
import { BoardAvisosDominio, FaQuestionCircle, Header, HeaderRow, MainContainer, NextStep, Passo, PassoBox } from "../Home/styles";
import { useParams } from 'react-router';
import TextInputComponent from "../../components/TextInputComponent";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { MessageBox, StyledColumn } from "./styles";


const Ticket =()=>{
    const { hash } = useParams();
    const context=useContext(UserContext);
    const [TextAreaValue,setTextAreaValue]=useState<string>('');
    const [refresh,setRefresh]=useState<any>(0); 
    const [isFetching,setIsFetching]=useState<boolean>(false);
    const navigate = useNavigate();
    
    const checkIfHasHash=()=>{
        if(!hash){
            //navigate('/confirmacao');
            console.log('não tem nada');
        }
    }

    const {data:messageData,isLoading:dataResponseSpeciesIsLoading}=useQuery('dataMessages', async ()=>{
        const {data}= await axios.get(`https://ctt.apihn.co/api/messages/${hash}`);
        return data;
    });

   
    const sendMessage=()=>{

        if(!TextAreaValue){
            setRefresh((prev:any) => prev + 1);
            return;
        }
        setIsFetching(true);
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }

        axios.put(`https://ctt.apihn.co/api/messages/${hash}`,{
            message: TextAreaValue
          },{headers:headers}).then((response) => {
            alert("Mensagem enviada com sucesso!");
            setTextAreaValue('');
            context.setConfirmation(true);
            navigate('/confirmacao');
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(()=>{
        
        checkIfHasHash();
    },[]);

    return(
        <>
            <Header>
                <Container className="ps-4 pe-4">
                        <HeaderRow>
                            <Col md={3}className="d-flex align-items-center justify-content-center justify-content-md-start"><a href="https://www.hostnet.com.br/" target="_blank"><Hostnetlogo/></a></Col>
                            <Col md={6}className="d-none d-md-flex align-items-center justify-content-center"><h1 className="d-none d-lg-flex">Hospede seu site ou registre seu domínio</h1></Col>
                            <Col md={3}className="d-none d-md-flex flex-column  align-items-end justify-content-center"><a href="https://www.hostnet.com.br/atendimento-ao-cliente/" target='_blank'><FaQuestionCircle/></a></Col>
                        </HeaderRow>
                </Container>
            </Header>
            
            <main>
            <MainContainer className="mt-5 mb-5 ps-md-5 pe-md-5 ps-4 pe-4">
                
                <Row>
                    <BoardAvisosDominio className="p-4 ps-5 pe-5">
                        <form action="#" onSubmit={(e)=>{
                            e.preventDefault();
                            sendMessage();
                        }}>
                        <Row>
                        <Col md={6}>
                                <TextInputComponent disabled={true} maxLength={90} className="mt-3" type="text" label="Categoria" mandatory={false} value={messageData?.data.category||'Carregando dados...'} />
                                <TextInputComponent disabled={true} maxLength={90} className="mt-3" type="text" label="Email" mandatory={false} value={messageData?.data.email||'Carregando dados...'} />
                                <TextInputComponent disabled={true} maxLength={90} className="mt-3" type="text" label="Assunto" mandatory={false} value={messageData?.data.subject||'Carregando dados...'} />
                                <label className="mt-3" htmlFor="mensagem"> Mensagem:</label>
                                <MessageBox id="mensagem" >
                                    {messageData?.data.message||'Carregando dados...'}
                                </MessageBox>
                        </Col>
                        <StyledColumn md={6} >
                            <TextInputComponent style={{height:'100%'}} rows={5} value={TextAreaValue} refresh={refresh} onChange={e=>setTextAreaValue(e.target.value)} maxLength={1000} className="mt-3" type="textarea" label="Adicionar mensagem" mandatory={true} />
                        </StyledColumn>
                        </Row>
                       
                        <Row className="mt-4">
                            
                            <Col >
                                <div style={{display:'flex',justifyContent:'end'}}>
                                    <NextStep type="submit" disabled={isFetching} className="mt-3">{isFetching?<CircularProgress style={{width:'30px',height:'30px'}}/>:'Enviar mensagem'}</NextStep>
                                </div>
                            </Col>
                            
                        </Row>
                        </form>
                    </BoardAvisosDominio>
                </Row>
                
            </MainContainer>
            </main>
            
        </>
    );
};

export default Ticket;